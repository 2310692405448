<template>
	<div>
		<!-- 圣杯布局 -->
		<Header style="position: fixed;"></Header>
		<div style="height: 60px;"></div>
		<div class="banner" >
			<div class="left-text">
				<div style="color: #4B4B4B;">专注数智化产业链接与</div>
				<div style="color: #BB9468;">科创孵化平台</div>
				<div style="height: 20px;"></div>
				<div class="btn_style" @click="$router.push('/about')">
					了解更多
				</div>
			</div>
		</div>
		 <!-- 公司介绍 -->
		<div class="container" style="background: linear-gradient(to top, #F5F5F5 38%, #fff 62%);">
			<div class="leftRight"></div>
			<!-- 内容 -->
			<section class="center">
				<div style="color: #4B4B4B;">
					<div class="module_title">公司介绍</div>
					<div class="module_subtitle">聚焦于数字信息、数字安全、数字文化、数字能源四大产业</div>
					<div class="flex-between">
						<div>
							<el-image :src="require('../static/images/index/Mask-group.png')" style="width: 510px;height: 440px;"></el-image>
						</div>
						<div>
							<div style="width: 615px;font-size: 15px;line-height: 25px;text-align: justify;text-justify: auto;">
								<div>
									广东网宇科技股份有限公司（以下简称“网宇科创”）是一家专注数字化产业链接与科技创新孵化的平台公司，聚焦于数字信息、数字安全、数字文化、数字能源四大产业。成立近十年以来，致力于产业数字化的创新与深度融合，助力不同行业数字科技创新与产业升级，实现价值共赢。
								</div>
								<ul style="padding-left: 15px;margin-top: 10px;">
									<li>数字信息，包括数字应用系统与平台、网络信息化软硬件等领域。</li>
									<li>数字安全，包括以运维安全、数据治理、工业数字化等安全领域。</li>
									<li>数字文化，包括数字影视、数字传媒、数字设计、数字教育、数字图文等领域。</li>
									<li>数字能源，包括数字化绿色能源、数字化储能等相应领域。</li>
								</ul>
							</div>
							
							<div style="margin-top: 40px;">
								<div class="flex_items" style="margin-bottom: 15px;"  v-for="(item,index) in IntroList" :key="index">
									<div style="margin-right: 50px;">
										<el-image :src="item.src" style="width: 50px;height: 50px;"></el-image>
									</div>
									<div>
										<p style="font-size: 18px;margin-bottom: 5px;">{{item.title}}</p>
										<p>{{item.con}}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div class="leftRight"></div>
		</div>
		<!-- 投资领域 -->
		<div class="container">
			<div class="leftRight"></div>
			<!-- 内容 -->
			<section class="center">
				<div style="color: #4B4B4B;">
					<div class="module_title">投资领域</div>
					<div class="module_subtitle">创新之道：探索人工智能、大数据、网络安全、数字文化与新能源的投资未来</div>
					<div >
						<el-image :src="require('../static/images/index/Group 54.jpg')"></el-image>
					</div>
				</div>
			</section>
			<div class="leftRight"></div>
		</div>
		
		<!-- 资讯中心 -->
		<div class="container">
			<div class="leftRight"></div>
			<!-- 内容 -->
			<section class="center">
				<div style="color: #4B4B4B;">
					<div class="module_title">资讯中心</div>
					<div class="module_subtitle">数字化革新，投资未来。探索数字化产业的最新资讯与前沿趋势</div>
					<div class="vue-flex" style="justify-content: space-around;">
						<div v-for="(item,index) in newList" :key="index" @click="newBtn(item.id)" style="cursor: pointer;" class="animation">
							<p>
								<el-image :src="item.src" class="animation_son"></el-image>
							</p>
							<p style="color: #053A8A;font-weight: 600;margin: 20px 0;font-size: 16px;">{{item.title}}</p>
							<p style="width: 270px;margin-bottom: 10px;">{{item.con}}</p>
							<p style="color: #B3B3B3;">{{item.time}}</p>
						</div>
					</div>
				</div>
			</section>
			<div class="leftRight"></div>
		</div>
		
		<!-- 地址、邮箱 -->
		<div style="margin-top: 70px;height: 140px;color: #ffffff;" class="vue-flex">
			<div class="flex_items" style="width: 50%;background-color: #264472;justify-content:flex-end;">
				<div style="margin-right: 30px;">
					<el-image :src="require('../static/images/index/lx01.png')" style="width: 72px;height: 63px;"></el-image>
				</div>
				<div style="margin-right: 100px;">
					<p style="font-size: 18px;margin-bottom: 10px;">联系地址</p>
					<p>广东省 东莞市 南城区 黄金路1号天安数码城H1栋16-17楼</p>
					<p>福建省 厦门市 集美区 软件园三期F10栋</p>
				</div>
			</div>
			<div class="flex_items" style="width: 50%;background-color: #D6B98E;">
				<div style="margin:0 30px 0 100px;">
					<el-image :src="require('../static/images/index/lx02.png')" style="width: 87px;height: 68px;"></el-image>
				</div>
				<div>
					<p style="font-size: 18px;margin-bottom: 10px;">联系方式</p>
					<p>Bp@wangyu.com</p>
				</div>
			</div>
		</div>
		
		<!-- 投资领域 -->
		<div class="container" style="margin-top: 0px;background-color: #F5F5F5;padding: 70px 0 100px 0;">
			<div class="leftRight"></div>
			<!-- 内容 -->
			<section class="center">
				<div style="color: #4B4B4B;">
					<div class="module_title">合作伙伴</div>
					<div class="module_subtitle">携手合作，共创数字化时代的投资新篇章。与业界领先合作伙伴一同助力数字化产业的创新发展。</div>
					<div style="max-width: 1200px;">
						<swiper :options="swiperOptions" ref="mySwiper" @mouseenter.native="swiperMouseEnter" @mouseleave.native="swiperMouseLeave">
						  <swiper-slide v-for="(item, index) in logoList" :key="index">
							 <img :src="item" :alt="`Slide ${index + 1}`" />
						  </swiper-slide>
						</swiper>
					</div>
				</div>
			</section>
			<div class="leftRight"></div>
		</div>
		
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from "@/components/header";
	import Footer from "@/components/footer";
	import 'swiper/css/swiper.css';
	import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
	export default {
		data() {
			return {
				IntroList: [
					{ src: require('../static/images/index/Group 55.png'),title: '使命',con: '链接全球科创资源，构建数字化产业生态，加速创新成果转化，共创数智化未来'},
					{ src: require('../static/images/index/Group 56.png'),title: '愿景',con: '成为全球领先的数智化产业链接与科创孵化平台'},
					{ src: require('../static/images/index/Group 57.png'),title: '价值观',con: '创新驱动，开放合作，用户导向，协作共赢'},
				],
				newList: [
					{ id: 1,src: require('../static/images/index/news1.jpg'),title: '证监会：加大长期资金对股权科创......',con: '推动相关行业主管部门完善法律法规，加大以社保基金、保险资金、养老基金等为代表的长......',time: '2023-10-26'},
					{ id: 2,src: require('../static/images/index/new2.jpg'),title: '证监会：支持头部证券公司通过并购......',con: '推动证券公司投行、投资、投研协同联动，不断提升服务实体经济、服务注册制改革能力，......',time: '2023-10-26'},
					{ id: 3,src: require('../static/images/index/new3.jpg'),title: '广东设立新型储能产业天使投资基金',con: '设立广东省新型储能产业天使投资基金，重点投向种子期、初创期、成长期科技型企业，建......',time: '2023-10-26'},
				],
				logoList: [
					require('../static/images/index/logo/Group 65.jpg'),
					require('../static/images/index/logo/Group 66.png'),
					require('../static/images/index/logo/Group 67.png'),
					require('../static/images/index/logo/Group 68.png'),
					require('../static/images/index/logo/Group 69.png'),
					require('../static/images/index/logo/Group 70.jpg'),
					require('../static/images/index/logo/Group 71.png'),
					require('../static/images/index/logo/Group 72.png'),
					require('../static/images/index/logo/Group 73.png'),
					require('../static/images/index/logo/Group 74.png'),
				],
				swiperOptions: {
					slidesPerView: 5, 
					// spaceBetween: 20, // 设置图之间的间距
					loop: true,
					autoplay: {
					  delay: 1000,
					},
				},
			}
		},
		components: {
			Header,
			Footer,
			Swiper,
			SwiperSlide,
		},
		mounted() {
			
		},
		methods: {
			swiperMouseEnter() {
				this.$refs.mySwiper.$swiper.autoplay.stop();
			},
			swiperMouseLeave() {
				this.$refs.mySwiper.$swiper.autoplay.start();
			},
			newBtn(id) {
				let routeUrl = this.$router.resolve({
					path:'article',
					query:{
						id
					}
				});
				window.open(routeUrl.href, "_blank");
			},
		},
	}
</script>

<style scoped>
	.container {
		margin-top: 70px;
		display: flex;
		flex-direction: row;
		flex: 1;
	}
	.center {
		flex: 0 0 1200px;
	}
	.leftRight {
		flex: 1;
	}
	.footer {
		text-align: center;
	}
	.banner {		
		height: 55vh;		
		display: flex;		
		align-items: center;		
		justify-content: center;		
		background: url("../static/images/index/444.jpg") no-repeat center center;		
		background-size: cover; 	
	}
	.banner .left-text {
		width: 1000px;
		font-size: 28px;
		font-weight: 400;
		color: #4B4B4B;
	}
	.module_title {
		font-size: 28px;
		font-weight: 400;
		color: #4B4B4B;
		text-align: center;
		margin: 30px 0 20px 0;
	}
	.module_subtitle {
		margin-bottom: 60px;
		font-size: 16px;
		color: #D6B98E;
		text-align: center;
	}
	.btn_style {
		width: 115px;
		height: 37px;
		text-align: center;
		line-height: 37px;
		background-color: #26497E;
		color: #ffffff;
		font-size: 16px;
		border-radius: 20px;
		cursor: pointer;
	}
	.swiper-slide {
	  box-sizing: border-box; /* 确保padding不会影响元素的实际尺寸 */
	  padding-right: 20px; /* 设置图之间的间距，此处与spaceBetween一致 */
	}
	.swiper-wrapper {
	  display: flex;
	}
	.swiper-slide img {
	  width: 100%; /* 图片宽度填充整个swiper-slide */
	  height: auto; /* 根据宽度等比例缩放图片 */
	}
	.animation_son {
		width: 270px;
		height: 213px;
	}
	.animation .animation_son {
		transition: transform 0.5s ease;
	}
	.animation:hover .animation_son {
		transform: scale(1.1);
	}
</style>
